.helpPage {
    margin: 20px;
}

.helpPage.ul {
    margin-top: 10px;
    margin-bottom: 10px;
}

h3 {
    margin-left: 10px;
    padding-top: 10px;
}

.subContent {
    margin-top: 30px;
}