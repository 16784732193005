#search-button {
    background-color: rgb(217, 246, 246);
    width:100%;
    border:1px solid rgb(62, 136, 136);
}

.titel-div{
    width:100px;
    margin-top:-30px;
    margin-left:5px;
    padding-left: 5px;
    background: linear-gradient( white 50%, rgb(217, 246, 246) 50%)
}