.newjob {
    margin-top: 10px;
    margin-left: 20px;
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: rgb(16, 166, 68);
    background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0));
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }
  
  .newjob:hover {
    background-color: rgb(53, 150, 87);
  }
  
  .newjob:active {
    box-shadow:
      inset -2px -2px 3px rgba(255, 255, 255, 0.6),
      inset 2px 2px 3px rgba(0, 0, 0, 0.6);
  }

  .newjob:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  } 

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confermationbox-content{
    width: 400px;
    margin: 15px;
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .configrationBoxh3{
    margin-bottom: 20px;
  }

  .configrationBoxh6{
    margin-left: 20px;
  }

  .styled {
    border: 0;
    line-height: 2.5;
    padding: 0 10px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0));
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }

  .styled.bgColor {
    background-color: rgba(255, 0, 0, 1);
  }
  
  .styled:hover {
    background-color: rgba(220, 0, 0, 1);
  }
  
  .styled:active {
    box-shadow:
      inset -2px -2px 3px rgba(255, 255, 255, 0.6),
      inset 2px 2px 3px rgba(0, 0, 0, 0.6);
  }

  .modalContent {
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%; /* Add this line to limit the width */
  }

  #create-admin {
    background-color: rgb(217, 246, 246);
    width:100%;
    border:1px solid rgb(62, 136, 136);  
  }

  .create-admin-tab {
    width:170px;
    margin-top:-30px;
    padding-left: 5px;
    background: linear-gradient( white 50%, rgb(217, 246, 246) 50%)
  }