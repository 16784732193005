 .formRow {
    display: flex;
    grid-template-columns: 1fr 1fr; 
    width: 50%;
  }

  #stuck-game-search-button {
    background-color: rgb(217, 246, 246);
    width:100%;
    border:1px solid rgb(62, 136, 136);  
  }

  .stuckgame-titel-div{
    width:270px;
    margin-top:-30px;
    padding-left: 5px;
    background: linear-gradient( white 50%, rgb(217, 246, 246) 50%)
  }

  .formCheck{
    margin-top: 10px;
    margin-left: 20px;
  }

  .date{
    background-color: white;
  }

  .copyButton{
    background-color: #2196F3;
    border-top-right-radius: 50%;
    border-color: #2196F3;
    color: white;
    margin-top: 5px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 40%;
    padding: 5px;
  }

  #outlined-basic{
    background-color: white;
  }

