  #allCheckbox{
    margin-left: 30px;
  }
  #rowCheckbox{
    margin-left: 30px;
  }

  .btn-primary{
    font-size: 15px;
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }
  /* edit button from clode game job */
  .edit {
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0));
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }

  .edit.bgColor {
    background-color: rgb(84, 201, 227);
  }
  
  .edit:hover {
    background-color: rgb(22, 154, 215);
  }
  
  .edit:active {
    box-shadow:
      inset -2px -2px 3px rgba(255, 255, 255, 0.6),
      inset 2px 2px 3px rgba(0, 0, 0, 0.6);
  }
  
  .slider:hover {
    background-color: rgb(53, 150, 87);
  } 
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow:
    inset 2px 2px 3px rgba(255, 255, 255, 0.6),
    inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
 
  #reset-button{
    box-shadow:
    inset 2px 2px 3px rgba(255, 255, 255, 0.6),
    inset -2px -2px 3px rgba(0, 0, 0, 0.6);
    height: 45px;
    margin-top: 14px;
  }
  .modal-box{
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%;
  }

  .message-content{
    width: 300px;
    margin: 15px;
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    color:  rgb(0, 0, 0);
    pointer-events: none;
    border-color: black;
    opacity: var(--bs-btn-disabled-opacity);
    background-color: #ccc;
    cursor: not-allowed;
  } 