  .copyButton{
    background-color: #2196F3;
    border-top-right-radius: 50%;
    border-color: #2196F3;
    color: white;
    margin-top: 5px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 40%;
    margin-right: 0;
    margin-left: 95%;
    margin-bottom: -30px;
  }

  .dissable {
    display: none;
  }

  .header-button {
    font-weight: bold;
    border: 0;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: inherit;
  }

  .header-button span {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-size: 15px;
  }


  .header-button:focus {
    outline: none;
  }

  .session-data {
    transition: all .5s;
    margin: 0;
    border: 1px solid #f1f1f1;
    border-top: 0;
    height: auto;
    opacity: 1;
    padding: 20px;
  }

  .column-data {
    background-color: rgb(245, 245, 245);
    margin: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 2px;
  }

  .highlighted {
    background-color: yellow;
    font-weight: bold;
  }