.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%; /* Add this line to limit the width */
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .subDiv{
    display: flex;
  }
  .popupLabal{
    display: block;
    margin-top: 16px;
    margin-right: 10px;
  }
  .note{
    color: red;
    font-size: 10px;
    margin: 10px;
  }
   /* delete button from close game job */
   .styled {
    border: 0;
    line-height: 2.5;
    padding: 0 10px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0));
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }

  .styled.bgColor {
    background-color: rgba(255, 0, 0, 1);
  }
  
  .styled:hover {
    background-color: rgba(220, 0, 0, 1);
  }
  
  .styled:active {
    box-shadow:
      inset -2px -2px 3px rgba(255, 255, 255, 0.6),
      inset 2px 2px 3px rgba(0, 0, 0, 0.6);
  }


  /* create new job button */
  .newjob {
    margin-top: 10px;
    margin-left: 20px;
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: rgb(16, 166, 68);
    background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0));
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }
  
  .newjob:hover {
    background-color: rgb(53, 150, 87);
  }
  
  .newjob:active {
    box-shadow:
      inset -2px -2px 3px rgba(255, 255, 255, 0.6),
      inset 2px 2px 3px rgba(0, 0, 0, 0.6);
  }

  .newjob:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  } 

  .error-message{
    color: red;
    font-size: 10px;
  }

  #popupbox{
    width: 500px;
  }

  .modal-box{
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%;
  }

  .secons-div-labal{
    margin-top: 4px;
    margin-left: 20px;
  }

  .form-control{
    width: 200px;
  }

  .time{
    display: flex;
  }
  
  #time-colume{
    font-size: 20px;
    margin-left: 10px;
  }