  .secondDiv{
    margin-left: 30px;
  } 

  .firstDiv{
    margin-left: 30px;
    margin-top: 7px
  }

  .archived-titel-div {
    width: 350px;
    margin-top:-30px;
    margin-left:5px;
    padding-left: 5px;
    background: linear-gradient( white 50%, rgb(217, 246, 246) 50%)
  }

  #filterButton{
    display: block;
    background-color: rgb(217, 246, 246);
    border:1px solid rgb(62, 136, 136);
  }

  .searchField{
    display: flex;
  }

  #outlined-basic{
    background-color: white;
  }

  .radioBox{
    margin-top: 0px;
    margin-left: 20px;
  }

  .horizontalRedioButton{
    margin-bottom: 10px;
    display: flex;
  }

  #reset-button{
    box-shadow:
    inset 2px 2px 3px rgba(255, 255, 255, 0.6),
    inset -2px -2px 3px rgba(0, 0, 0, 0.6);
    height: 45px;
    margin-top: 14px;
  }

  