  #audit-searchButton{
    display: flex;
    background-color: rgb(217, 246, 246);
    width:100%;
    border:1px solid rgb(62, 136, 136); 
  }

  .tablerow{
    border-color: black;
  }

  .col-1{
    border: none;
  }

  .tableDiv{
    overflow:scroll;
    max-height: 800px;
    margin-bottom: 5px;
  }

  #reset-button{
    margin-left: 40px;
    box-shadow:
    inset 2px 2px 3px rgba(255, 255, 255, 0.6),
    inset -2px -2px 3px rgba(0, 0, 0, 0.6);
    height: 45px;
    margin-top: 14px;
  }

  #noReplay{
    color: red;
  }