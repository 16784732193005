.innerbox {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 20px;
    border: 1px solid #949090;
    border-radius: 10px;
    width: 50%;
}
.outerbox{
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.button {
    margin-top: 15px;
    width: 70%;
    margin-bottom: 10px;
}
.reset-password-input{
    margin-top: 20px;
    width: 70%;
}