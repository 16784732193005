#FindGameByPlayerIdButton{
    margin-top: 20px;
  }

  .form-control{
    width: 200px;
  }

  .btn-primary{
    font-size: 15px;
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }

  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    color:  rgb(0, 0, 0);
    pointer-events: none;
    border-color: black;
    opacity: var(--bs-btn-disabled-opacity);
    background-color: #ccc;
    cursor: not-allowed;
  }