.navbar-brand {
  margin-left: 10px;
}

#not-active{
  color: #007bff;
}

#active-button {
  color: white;
}

.active-button {
  background-color: #007bff;
  border-radius: 10px;
}

#dropdownMenuButton{
  border-radius: 10px;
}

.nav-link.active {
  background-color: #007bff;
  border-radius: 5px;
  margin-left: 0px;
  text-decoration: none;
}

.navigation {
  background-color: #e5e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.outlet{
  margin-top: 60px;
}

.dropdown-toggle{
  color: blue;
}

.app-name {
  margin-right: 10px;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.hamburger-menu {
  display: none;
}

@media (max-width: 991px) {
  .hamburger-menu {
    display: block;
    font-size: 1.7rem;
  }

  .menu-link {
    display: none;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    height: 1.7rem;
    display:flex;
    grid-column: 2/5;
    align-items:flex-start;
    padding-left: 1rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    flex-direction: column;
    align-items: start;
    background-color: rgb(231, 239, 236);
    padding: 10px;
    border: 1px solid black;
    border-radius: 5%;
  }

  .health-check-menu ul{
    flex-direction: column;
    align-items: start;
    background-color: rgb(231, 239, 236);
    padding: 10px;
    border: 1px solid black;
    border-radius: 5%;
  }
}

@media (max-width: 509px) {
  .mobile-menu-link {
    padding-left: 0rem;
  }
}

.userRole {
  font-size: 0.75em;
  margin: -2px;
}

.userName {
  margin: -2px;
}


.userTab {
  margin-left: auto; 
  padding-left: 10px;
  margin-right: 0;
  display: flex;
}
.health-div{
  margin-left: 10px;
}

#popup-status{
  padding: 5px;
  border-radius: 5%; 
}

#img-button-error {
  background-image: url('../img/flat_ecg_img.gif');
  background-size: cover;
  height: 35px;
  margin-right: 20px;
  margin-top: 0px;
  width: 60px;
  border-radius: 10%;
  background-repeat: no-repeat; 
  background-position: center; 
}

#img-button {
  background-image: url('../img/giphy.gif');
  background-size: cover;
  height: 35px;
  margin-right: 20px;
  margin-top: 0px;
  width: 60px;
  border-radius: 10%;
  background-repeat: no-repeat; 
  background-position: center; 
}
.health-common{
  float: right;
}