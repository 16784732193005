table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  label{
    margin-left: 10px;
  }

  .table{
    overflow: auto; 
    max-height: 400px;
  }

  .configrationBoxh6{
    margin-left: 20px;
  }

  .configrationBoxh3{
    margin-bottom: 20px;
  }

  #forceCloseButton{
    margin-left: 30px;
  }
  
  #replayButton{
    margin-left: 5px;
  }

  .btn-primary{
    font-size: 15px;
    box-shadow:
      inset 2px 2px 3px rgba(255, 255, 255, 0.6),
      inset -2px -2px 3px rgba(0, 0, 0, 0.6);
  }

  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    color:  rgb(0, 0, 0);
    pointer-events: none;
    border-color: black;
    opacity: var(--bs-btn-disabled-opacity);
    background-color: #ccc;
    cursor: not-allowed;
  } 

  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  #checkbox_selectAll{
    text-align:center;
    vertical-align: middle;
  }

  .modal-box{
    background-color: rgb(250, 249, 249);
    padding: 30px;
    padding-right: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%;
  }

  #confirm-button{
    margin-left: 160px;
    margin-top: 30px;
    border-radius: 1px;
  }
  
  #cancle-button{
    margin-left: 10px;
    border-radius: 1px;
    margin-top: 30px;
  }