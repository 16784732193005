  .flash-alert {
    color: white;
    border-radius: 10px;
    position:fixed;
    top: 100px;
    left: 45%;
    padding: 20px;
    display: flex;
    align-items: center;
    z-index: 1111;
    text-align: center;
  }
  .flash-alert p {
    margin: 0;
  }
  .flash-alert-error {
    background: rgb(188, 79, 79);
  }
  .flash-alert-success {
    background: rgb(62, 176, 62);
  }
  .flash-close {
    color: white;
    cursor: pointer;
    margin-right: 10px;
  }